import request from "@/utils/request";

export function searchCompany(data) {
  return request({
    url: `/v1/company/search`,
    method: "get",
    params: data,
  });
}

export function getCompany(id) {
  return request({
    url: `/v1/company/${id}`,
    method: "get",
  });
}
