<template>
  <div class="EnterpriseDetails" v-loading="loading">
    <HomeSwiper src="/images/banner.jpg"></HomeSwiper>
    <div class="row tops">
      <div class="carouselBox">
        <img :src="companyData.coverUrl" class="carouselImg" alt="" />
      </div>
      <div class="enterpriseBox">
        <h3>展位：{{ companyData.boothName }}</h3>
        <h2>{{ companyData.companyName }}</h2>
        <h4>{{ companyData.introduction }}</h4>
      </div>
    </div>
    <div class="video" v-show="companyData.adUrl">
      <h2 style="text-align: center">视频展示</h2>
      <h3 style="text-align: center">Video Presentat</h3>
      <div class="player">
        <Player ref="Player"></Player>
      </div>
    </div>

    <div class="contact">
      <h2 class="title">联系方式</h2>
      <div class="contactCom">
        <el-row>
          <el-col :span="12" :xs="24">
            <label>联系人</label> {{ companyData.linkMan }}</el-col
          >
          <el-col :span="12" :xs="24">
            <label>联系电话</label> {{ companyData.linkTel }}</el-col
          >
          <el-col :span="12" :xs="24">
            <label>联系邮箱</label>
            {{ companyData.linkMail }}</el-col
          >
          <el-col :span="12" :xs="24">
            <label>邮编</label>
            {{ companyData.postCode }}</el-col
          >
          <el-col :span="12" :xs="24">
            <label>地址</label>
            {{ companyData.address }}</el-col
          >
          <el-col :span="12" :xs="24">
            <label>官网</label>
            {{ companyData.officialSite }}</el-col
          >
        </el-row>
      </div>
    </div>

    <div class="box-card">
      <el-card>
        <div slot="header" class="header">展品与服务</div>
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="box-card"
            v-for="item in goodsData"
            :key="item.id"
            :xs="24"
            :sm="8"
            :md="6"
            :lg="6"
            :xl="6"
          >
            <div class="good-box" @click="handleClick(item.id)">
              <div class="image-box">
                <img v-if="item.coverUrl" :src="item.coverUrl" class="image" />
                <div v-else class="txt">{{ item.name }}</div>
              </div>
              <div class="bottom">
                <div class="price">
                  <span class="num">￥{{ item.price }}</span>
                  <span class="norms">规格:{{ item.norms }}</span>
                </div>
                <div class="name">{{ item.name }}</div>
                <div class="tip">
                  品牌{{ item.brand }} 产地/服务地区:{{ item.place }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import HomeSwiper from "@/components/Cms/HomeSwiper";
import Player from "@/components/Cms/Player";

import { getCompany } from "@/api/cms/company.js";
import { listByCompany } from "@/api/cms/goods.js";

export default {
  components: {
    HomeSwiper,
    Player,
  },
  data() {
    return {
      loading: false,
      companyData: {},
      goodsData: [],
    };
  },
  methods: {
    handlePlay() {
      window.open(`/cms/player/${this.companyData.adEid}`);
    },
    handleClick(id) {
      window.open(`/cms/goods/show/${id}`);
    },
    getCompany() {
      this.loading = true;
      getCompany(this.$route.params.id).then((res) => {
        this.loading = false;

        this.companyData = res;
        this.$refs.Player.play(res.playUrl);
      });
    },
    listByCompany() {
      this.loading = true;
      listByCompany(this.$route.params.id).then((res) => {
        this.loading = false;
        this.goodsData = res;
      });
    },
  },
  created() {
    this.getCompany();
    this.listByCompany();
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 30px 0;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.carouselBox {
  width: 500px;
  height: 400px;
  position: relative;

  .carouselImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    border: 2px solid #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
}

.carouselBox .carousel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: -100px;
}

.el-carousel__item {
  width: 400px;
  height: 400px;
  border-radius: 20px;
  background-color: #000;
}

.row {
  display: flex;
  flex-direction: row;
}

.exhibitsBox {
  margin-top: 1em;
}

.enterpriseBox {
  text-align: left !important;
  margin-left: 60px;
}

.enterpriseBox h3 {
  margin-bottom: 30px;
}

.enterpriseBox h4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 13;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EnterpriseDetails {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.tops {
  width: 1200px;
  margin: 50px auto;
  align-items: center;
  justify-content: space-between;
}

.carouselBox {
  flex-shrink: 0;
}

/* video */
.video {
  padding-top: 10px;
  height: 660px;
  display: block;
  background-color: #fff;

  .player {
    width: 45%;
    height: 450px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}

.contact {
  background-color: #f8f8f8;
  padding: 30px 20px 60px 20px;

  .contactCom {
    max-width: 1230px;
    margin: auto;
    line-height: 50px;
    font-weight: 300;
  }

  .title {
    text-align: center;
    margin-top: 40px;
  }
}

h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

h3 {
  font-size: 22px;
}

.box-card {
  padding: 20px;
  max-width: 1280px;
  margin: auto;
}

.good-box {
  cursor: pointer;
  border: 1px solid #ededed;
  padding: 10px;

  .image-box {
    width: 100%;
    display: block;
    height: 200px;
    background-color: #fafafa;
    overflow: hidden;
  }

  .txt {
    padding: 10px;
    font-size: 26px;
    font-weight: 300;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  .bottom {
    margin-top: 10px;
    color: #3d3d3d;
    height: 100px;
    overflow: hidden;

    .price {
      .num {
        color: #f40;
        font-weight: 700;
      }

      .norms {
        font-size: 12px;
        margin-left: 20px;
      }
    }

    .name {
      margin-top: 15px;
      font-size: 13px;
      height: 35px;
      overflow: hidden;
    }

    .tip {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 700px) {
  h2 {
    font-size: 1.7rem;
    text-align: center;
  }
  h3 {
    font-size: 1.1rem;
    text-align: center;
  }
  .tops {
    display: block;
    width: auto;
  }
  .carouselBox {
    width: auto;
    margin: 1rem;
    height: 30rem;
    margin-bottom: 3rem;
    .carousel {
      top: 2rem;
    }
  }

  .video {
    height: 400px;
    .player {
      width: 80%;
    }
  }
  .el-carousel__item {
    width: 15rem;
    height: 20rem;
    margin-left: -1.4rem;
  }
  .enterpriseBox {
    margin: 1rem;
    width: auto;
  }
}
</style>
